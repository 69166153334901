/*Service*/
import {
  Client,
  GetNewClientIdRequest,
  CreateClientRequest,
  UpdateClientRequest,
  ListClientRequest,
  DeleteClientRequest,
  ReadClientRequest,
} from '../../../proto/accountpb/client_grpc_web_pb';
import { ListNoticeRequest } from 'proto/irspb/notice_grpc_web_pb';

import {
  stringToUTCProtoTimeSpan,
  stringToModalDate,
} from '../../../services/ConvertService';

/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';

/*Toast Notification*/
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatSeconds, formatPbDate } from 'lib/fmt';

/*Material UI Components*/
import {
  TableCell,
  TableRow,
  Typography,
  Checkbox,
  Button,
  IconButton,
  Box,
  MenuItem,
  InputLabel,
  Select,
  FormControlLabel,
  TextField,
  AppBar,
  Tabs,
  Tab,
  Modal,
  Backdrop,
  Fade,
} from '@material-ui/core';

/*Material UI Icons*/
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Create as EditIcon,
} from '@material-ui/icons';

/*Material UI Confirm*/
import { useConfirm } from 'material-ui-confirm';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Table Components*/
import { AddressTable } from './AddressTable';
import OwnerTable from './OwnerTable';
import NoteTable from './NoteTable';
import ContactInfoTable from './ContactInfoTable';
import NoticeTable from './NoticeTable';
import { ClientTypeTable } from './ClientTypeTable';
import { SubAccountTable } from './SubAccountTable';
import { SetupTable } from './SetupTable';

/*Country Dropdown Component*/
import CorrespondentSelect from '../../../components/AutoComplete/Correspondent';
import AccountNoSelect from '../../../components/AutoComplete/AccountNo';
import AccountNameSelect from '../../../components/AutoComplete/AccountName';
import MasterAccountNoSelect from '../../../components/AutoComplete/MasterAccountNo';
import AccountDesignatorSelect from '../../../components/Dropdown/AccountDesignator';
import CountriesSelect from '../../../components/AutoComplete/Countries';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';

/*Google Time Stamp Protobuf*/
const google_date = require('../../../google/type/date_pb.js');

/*======================================================================
Handles tab function
======================================================================*/
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
/*======================================================================
End of tab function
======================================================================*/

/*=========================================================================================
Add and Update system code function
===========================================================================================*/
function saveClientAccount(
  accountClient,
  rows,
  isAdd,
  closeModal,
  accountId,
  correspondent,
  accountName,
  accountNo,
  masterAccountNo,
  status,
  accountDesignator,
  activatedAt,
  disabledAt,
  broker,
  capacity,
  privacy,
  dayTrader,
  multiplier,
  legalEntity,
  investmentObj,
  investmentObjDate,
  taxCountry,
  tefra,
  w8W9,
  w8W9ReceivedDate,
  baseCurrency,
  clearingBroker
) {
  if (!correspondent) {
    notifyError('Correspondent is required.');
    return;
  }
  if (!accountNo) {
    notifyError('Account no. is required.');
    return;
  }
  if (!accountName) {
    notifyError('Account name is required.');
    return;
  }
  if (!legalEntity) {
    notifyError('Legal entity is required.');
    return;
  }
  if (!w8W9ReceivedDate) {
    notifyError('W8W9 received date is required.');
    return;
  }

  // CONVERT DATE TO TIMESTAMP PROTO
  let pbActivatedAt = stringToUTCProtoTimeSpan(activatedAt);
  let pbDisabledAt = stringToUTCProtoTimeSpan(disabledAt);
  let newInvestmentObjDate = new Date(investmentObjDate);
  let gglInvestmentObjDate = new google_date.Date();
  gglInvestmentObjDate.setMonth(newInvestmentObjDate.getMonth() + 1);
  gglInvestmentObjDate.setYear(newInvestmentObjDate.getFullYear());
  gglInvestmentObjDate.setDay(newInvestmentObjDate.getDate());

  let newW8W9ReceivedDate = new Date(w8W9ReceivedDate);
  let gglW8W9ReceivedDate = new google_date.Date();
  gglW8W9ReceivedDate.setMonth(newW8W9ReceivedDate.getMonth() + 1);
  gglW8W9ReceivedDate.setYear(newW8W9ReceivedDate.getFullYear());
  gglW8W9ReceivedDate.setDay(newW8W9ReceivedDate.getDate());

  if (isAdd) {
    if (!clearingBroker) {
      notifyError('clearing broker is required.');
      return;
    }

    let addReqClient = new Client();

    addReqClient.setAccountId(accountId);
    addReqClient.setCorrespondent(correspondent);
    addReqClient.setAccountName(accountName);
    addReqClient.setAccountNo(accountNo);
    addReqClient.setMasterAccountNo(masterAccountNo);
    addReqClient.setStatus(status);
    addReqClient.setAccountDesignator(accountDesignator);
    addReqClient.setActivatedAt(pbActivatedAt);
    addReqClient.setDisabledAt(pbDisabledAt);
    addReqClient.setBroker(broker);
    addReqClient.setCapacity(capacity);
    addReqClient.setPrivacyContent(privacy);
    addReqClient.setDayTrader(dayTrader);
    addReqClient.setMultiplier(multiplier);
    addReqClient.setLegalEntity(legalEntity);
    addReqClient.setInvestmentObjectiveCode(investmentObj);
    addReqClient.setInvestmentObjectiveDate(gglInvestmentObjDate);
    addReqClient.setTaxCountry(taxCountry);
    addReqClient.setTefra(tefra);
    addReqClient.setW8w9(w8W9);
    addReqClient.setW8w9ReceivedDate(gglW8W9ReceivedDate);
    addReqClient.setBaseCurrency(baseCurrency);

    let addReq = new CreateClientRequest();

    addReq.setClient(addReqClient);
    addReq.setClearingBroker(clearingBroker);

    accountClient.createClient(addReq, {}, (err, res) => {
      if (err) {
        console.error(err.message);
        notifyError(err.message);
        return;
      }
      let obj = res.toObject().client;

      let newAccount = {
        accountId: obj.accountId,
        correspondent: correspondent,
        accountName: accountName,
        accountNo: accountNo,
        masterAccountNo: masterAccountNo,
        status: status,
        accountDesignator: accountDesignator,
        activatedAt: moment(
          new Date(pbActivatedAt.toObject().seconds * 1000)
        ).format('MM/DD/YYYY hh:mm'),
        disabledAt: pbDisabledAt
          ? moment(new Date(pbDisabledAt.toObject().seconds * 1000)).format(
              'MM/DD/YYYY hh:mm'
            )
          : null,
        broker: broker,
        capacity: capacity,
        privacyContent: privacy,
        dayTrader: dayTrader,
        multiplier: multiplier,
        legalEntity: legalEntity,
        investmentObjectiveCode: investmentObj,
        investmentObjectiveDate: moment(new Date(investmentObjDate)).format(
          'MM/DD/YYYY'
        ),
        taxCountry: taxCountry,
        tefra: tefra,
        w8w9: w8W9,
        w8w9ReceivedDate: moment(new Date(w8W9ReceivedDate)).format(
          'MM/DD/YYYY'
        ),
        baseCurrency: baseCurrency,
      };

      rows.push(newAccount);
      notifySuccess('New Account has been added.');
      closeModal();
    });
  } else {
    let updateReqClient = new Client();
    updateReqClient.setAccountId(accountId);
    updateReqClient.setCorrespondent(correspondent);
    updateReqClient.setAccountName(accountName);
    updateReqClient.setAccountNo(accountNo);
    updateReqClient.setMasterAccountNo(masterAccountNo);
    updateReqClient.setStatus(status);
    updateReqClient.setAccountDesignator(accountDesignator);
    updateReqClient.setActivatedAt(pbActivatedAt);
    updateReqClient.setDisabledAt(pbDisabledAt);
    updateReqClient.setBroker(broker);
    updateReqClient.setCapacity(capacity);
    updateReqClient.setPrivacyContent(privacy);
    updateReqClient.setDayTrader(dayTrader);
    updateReqClient.setMultiplier(multiplier);
    updateReqClient.setLegalEntity(legalEntity);
    updateReqClient.setInvestmentObjectiveCode(investmentObj);
    if (investmentObjDate && investmentObjDate !== 'Invalid date') {
      updateReqClient.setInvestmentObjectiveDate(gglInvestmentObjDate);
    }
    updateReqClient.setTaxCountry(taxCountry);
    updateReqClient.setTefra(tefra);
    updateReqClient.setW8w9(w8W9);
    updateReqClient.setW8w9ReceivedDate(gglW8W9ReceivedDate);
    updateReqClient.setBaseCurrency(baseCurrency);

    let updateReq = new UpdateClientRequest();

    updateReq.setClient(updateReqClient);
    updateReq.setClearingBroker('');

    accountClient.updateClient(updateReq, {}, (err) => {
      if (err) {
        console.error(err.message);
        notifyError(err.message);
        return;
      }
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].accountId === accountId) {
          rows[i].correspondent = correspondent;
          rows[i].accountName = accountName;
          rows[i].accountNo = accountNo;
          rows[i].masterAccountNo = masterAccountNo;
          rows[i].status = status;
          rows[i].accountDesignator = accountDesignator;
          rows[i].activatedAt = activatedAt
            ? moment(new Date(activatedAt)).format('MM/DD/YYYY hh:mm')
            : '--';
          rows[i].disabledAt = disabledAt
            ? moment(new Date(disabledAt)).format('MM/DD/YYYY hh:mm')
            : '--';
          rows[i].broker = broker;
          rows[i].capacity = capacity;
          rows[i].privacyContent = privacy;
          rows[i].dayTrader = dayTrader;
          rows[i].multiplier = multiplier;
          rows[i].legalEntity = legalEntity;
          rows[i].investmentObjectiveCode = investmentObj;
          rows[i].investmentObjectiveDate = investmentObjDate
            ? moment(new Date(investmentObjDate)).format('MM/DD/YYYY')
            : '--';
          rows[i].taxCountry = taxCountry;
          rows[i].tefra = tefra;
          rows[i].w8w9 = w8W9;
          rows[i].w8w9ReceivedDate = w8W9ReceivedDate
            ? moment(new Date(w8W9ReceivedDate)).format('MM/DD/YYYY')
            : '--';
          rows[i].baseCurrency = baseCurrency;
        }
      }
      notifySuccess('Client account has been updated.');
      closeModal();
    });
  }
}
/*=========================================================================================
End of add and update client account function
===========================================================================================*/

/*=========================================================================================
Delete client account function: Multiple
===========================================================================================*/
function removeClientAccount(
  accountClient,
  rows,
  setRows,
  accountIDs,
  handleClick,
  confirm
) {
  if (accountIDs.length < 1) {
    notifyError('No item is selected.');
    return;
  }

  confirm({
    description:
      'You are about to delete ' +
      accountIDs.length +
      ' Client Account(s) in your system. Please confirm your action.',
    confirmationText: 'Yes, Delete',
  })
    .then(() => {
      accountIDs.forEach(removeFunction);

      function removeFunction(item) {
        let deleteClientAccount = new DeleteClientRequest();
        deleteClientAccount.setAccountId(item);

        accountClient.deleteClient(deleteClientAccount, {}, (err) => {
          if (err) {
            console.error(err.message);
            notifyError(err.message);
          }

          let updatedData = [];

          for (let c = 0; c < rows.length; c++) {
            if (rows[c].accountId === item) {
              rows[c].status = 'disabled';
              updatedData.push(rows[c]);
            } else {
              updatedData.push(rows[c]);
            }
          }
          setRows(updatedData);
        });
      }
      handleClick('', '', true);
      notifySuccess(accountIDs.length + ' Client account has been disabled');
    })
    .catch(() => {
      /* ... */
    });
}
/*=========================================================================================
End of delete client account function: Multiple
===========================================================================================*/

/*=========================================================================================
Delete client account function: Single
===========================================================================================*/
function removeClientAccountSingle(
  accountClient,
  rows,
  setRows,
  accountId,
  accountNo,
  accountName,
  confirm
) {
  confirm({
    description:
      "You are about to delete Client Account '" +
      accountNo +
      ': ' +
      accountName +
      "' in your system. Please confirm your action.",
    confirmationText: 'Yes, Delete',
  })
    .then(() => {
      let deleteClientAccount = new DeleteClientRequest();
      deleteClientAccount.setAccountId(accountId);

      accountClient.deleteClient(deleteClientAccount, {}, (err) => {
        if (err) {
          console.error(err.message);
          notifyError(err.message);
          return;
        }

        let updatedData = [];

        for (let c = 0; c < rows.length; c++) {
          if (rows[c].accountId === accountId) {
            rows[c].status = 'disabled';
            updatedData.push(rows[c]);
          } else {
            updatedData.push(rows[c]);
          }
        }
        setRows(updatedData);
      });

      notifySuccess('Client Account has been disabled');
    })
    .catch(() => {
      // showToastMessage("error", "System error.");
    });
}
/*=========================================================================================
End of delete client account function: Single
===========================================================================================*/

function ClientAccountTable({ params }) {
  /*=========================================================================================
    Component style
  ===========================================================================================*/
  const classes = useStyles();
  const confirm = useConfirm();
  /*=========================================================================================
    End of component style
  ===========================================================================================*/
  const {
    clientServiceClient: accountClient,
    noticeServiceClient: notice,
  } = useContext(ServiceContext);

  /*=========================================================================================
    Start of paging logic
  ===========================================================================================*/
  const [page, setPageState] = useState({
    currentPage: 0,
    displayedRows: 100,
    totalRows: 0,
    searchSrc: '',
    sortOrder: {},
    filter: [],
  });

  useEffect(() => {
    if (page.data) {
      /*sorting*/
      let sortField = page.sortOrder.name;
      let sortDir = page.sortOrder.direction;

      let fullData = page.data.sort((a, b) => {
        let val1 = a[sortField];
        let val2 = b[sortField];

        if (typeof val1 === 'object') {
          if (val1.seconds) {
            val1 = a[sortField]
              ? moment(new Date(a[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
            val2 = b[sortField]
              ? moment(new Date(b[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
          } else {
            val1 = formatPbDate(a[sortField]);
            val2 = formatPbDate(b[sortField]);
          }
        }
        if (val1 < val2) {
          return 1 * (sortDir === 'asc' ? -1 : 1);
        } else if (val1 > val2) {
          return -1 * (sortDir === 'asc' ? -1 : 1);
        } else {
          return 0;
        }
      });

      /*filter*/
      if (page.filter.length > 0) {
        let columnFilter = page.filter;
        let filterParams = {
          accountDesignator:
            columnFilter[0].value !== undefined ? [columnFilter[0].value] : [],
          accountName:
            columnFilter[1].value !== undefined ? [columnFilter[1].value] : [],
          accountNo:
            columnFilter[2].value !== undefined ? [columnFilter[2].value] : [],
          activatedAt:
            columnFilter[3].value !== undefined ? [columnFilter[3].value] : [],
          broker:
            columnFilter[4].value !== undefined ? [columnFilter[4].value] : [],
          capacity:
            columnFilter[5].value !== undefined ? [columnFilter[5].value] : [],
          correspondent:
            columnFilter[6].value !== undefined ? [columnFilter[6].value] : [],
          createdBy:
            columnFilter[7].value !== undefined ? [columnFilter[7].value] : [],
          dayTrader:
            columnFilter[8].value !== undefined ? [columnFilter[8].value] : [],
          disabledAt:
            columnFilter[9].value !== undefined ? [columnFilter[9].value] : [],
          investmentObjectiveCode:
            columnFilter[10].value !== undefined
              ? [columnFilter[10].value]
              : [],
          investmentObjectiveDate:
            columnFilter[11].value !== undefined
              ? [columnFilter[11].value]
              : [],
          legalEntity:
            columnFilter[12].value !== undefined
              ? [columnFilter[12].value]
              : [],
          masterAccountNo:
            columnFilter[13].value !== undefined
              ? [columnFilter[13].value]
              : [],
          multiplier:
            columnFilter[14].value !== undefined
              ? [columnFilter[14].value]
              : [],
          privacyContent:
            columnFilter[15].value !== undefined
              ? [columnFilter[15].value]
              : [],
          status:
            columnFilter[16].value !== undefined
              ? [columnFilter[16].value]
              : [],
          taxCountry:
            columnFilter[17].value !== undefined
              ? [columnFilter[17].value]
              : [],
          tefra:
            columnFilter[18].value !== undefined
              ? [columnFilter[18].value]
              : [],
          w8w9:
            columnFilter[19].value !== undefined
              ? [columnFilter[19].value]
              : [],
          w8w9ReceivedDate:
            columnFilter[20].value !== undefined
              ? [columnFilter[20].value]
              : [],
        };

        const buildFilter = (filter) => {
          let query = {};
          for (let keys in filter) {
            if (filter[keys].constructor === Array && filter[keys].length > 0) {
              query[keys] = filter[keys];
            }
          }
          return query;
        };

        const filterData = (data, query) => {
          const filteredData = data.filter((item) => {
            for (let key in query) {
              if (
                key === 'createdAt' ||
                key === 'activatedAt' ||
                key === 'disabledAt'
              ) {
                if (item[key] !== undefined) {
                  item[key] =
                    typeof item[key] === 'object'
                      ? moment(new Date(item[key].seconds * 1000)).format(
                          'MM/DD/YYYY hh:mm'
                        )
                      : item[key];
                } else {
                  item[key] = '--';
                }
              }
              if (key.includes('Date')) {
                item[key] =
                  typeof item[key] === 'object'
                    ? formatPbDate(item[key])
                    : item[key];
              }
              if (item[key] === undefined || !query[key].includes(item[key])) {
                return false;
              }
            }
            return true;
          });
          return filteredData;
        };

        const query = buildFilter(filterParams);
        fullData = filterData(page.data, query);
      }

      var pageData = fullData.slice(
        page.currentPage * page.displayedRows,
        (page.currentPage + 1) * page.displayedRows
      );

      setRows(
        pageData.map((data) => ({
          accountId: data.accountId,
          correspondent: data.correspondent,
          accountNo: data.accountNo,
          accountName: data.accountName,
          masterAccountNo: data.masterAccountNo,
          status: data.status,
          accountDesignator: data.accountDesignator,
          activatedAt: data.activatedAt
            ? typeof data.activatedAt === 'object'
              ? moment(new Date(data.activatedAt.seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : data.activatedAt
            : '--',
          disabledAt: data.disabledAt
            ? typeof data.disabledAt === 'object'
              ? moment(new Date(data.disabledAt.seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : data.disabledAt
            : '--',
          broker: data.broker,
          capacity: data.capacity,
          privacyContent: data.privacyContent,
          dayTrader: data.dayTrader,
          multiplier: data.multiplier,
          legalEntity: data.legalEntity,
          investmentObjectiveCode: data.investmentObjectiveCode,
          investmentObjectiveDate:
            typeof data.investmentObjectiveDate === 'object'
              ? formatPbDate(data.investmentObjectiveDate)
              : data.investmentObjectiveDate,
          taxCountry: data.taxCountry,
          tefra: data.tefra,
          w8w9: data.w8w9,
          w8w9ReceivedDate:
            typeof data.w8w9ReceivedDate === 'object'
              ? formatPbDate(data.w8w9ReceivedDate)
              : data.w8w9ReceivedDate,
          createdAt: data.createdAt
            ? typeof data.createdAt === 'object'
              ? moment(new Date(data.createdAt.seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : data.createdAt
            : '--',
          createdBy: data.createdBy,
          baseCurrency: data.baseCurrency,
        }))
      );

      setDownloadableRows(
        fullData.map((data) => ({
          accountId: data.accountId,
          correspondent: data.correspondent,
          accountNo: data.accountNo,
          accountName: data.accountName,
          masterAccountNo: data.masterAccountNo,
          status: data.status,
          accountDesignator: data.accountDesignator,
          activatedAt: data.activatedAt
            ? typeof data.activatedAt === 'object'
              ? moment(new Date(data.activatedAt.seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : data.activatedAt
            : '--',
          disabledAt: data.disabledAt
            ? typeof data.disabledAt === 'object'
              ? moment(new Date(data.disabledAt.seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : data.disabledAt
            : '--',
          broker: data.broker,
          capacity: data.capacity,
          privacyContent: data.privacyContent,
          dayTrader: data.dayTrader,
          multiplier: data.multiplier,
          legalEntity: data.legalEntity,
          investmentObjectiveCode: data.investmentObjectiveCode,
          investmentObjectiveDate:
            typeof data.investmentObjectiveDate === 'object'
              ? formatPbDate(data.investmentObjectiveDate)
              : data.investmentObjectiveDate,
          taxCountry: data.taxCountry,
          tefra: data.tefra,
          w8w9: data.w8w9,
          w8w9ReceivedDate:
            typeof data.w8w9ReceivedDate === 'object'
              ? formatPbDate(data.w8w9ReceivedDate)
              : data.w8w9ReceivedDate,
          createdAt: data.createdAt
            ? typeof data.createdAt === 'object'
              ? moment(new Date(data.createdAt.seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : data.createdAt
            : '--',
          createdBy: data.createdBy,
          baseCurrency: data.baseCurrency,
        }))
      );

      if (page.searchSrc === 'search') {
        notifyInfo(page.totalRows + ' search results.');
      }

      setLoading(false);
    }
  }, [page]);

  const search = (
    type,
    cPage,
    rowsPerPage,
    field,
    sortDirection,
    filterValue
  ) => {
    if (type === 'search') {
      const pathWithParams =
        currentPath +
        '?search=true' +
        (correspondentValue ? '&correspondent=' + correspondentValue : '') +
        (accountNoValue ? '&accountNo=' + accountNoValue : '') +
        (masterAccountNoValue
          ? '&masterAccountNo=' + masterAccountNoValue
          : '') +
        (accountNameValue ? '&accountName=' + accountNameValue : '') +
        (accountDesignatorValue
          ? '&accountDesignator=' + accountDesignatorValue
          : '') +
        (brokerValue ? '&broker=' + brokerValue : '') +
        (statusValue ? '&status=' + statusValue : '');
      window.history.pushState({}, null, pathWithParams);

      setLoading(true);

      let listReq = new ListClientRequest();
      listReq.setCorrespondent(correspondentValue);
      listReq.setAccountNo(accountNoValue);
      listReq.setMasterAccountNo(masterAccountNoValue);
      listReq.setAccountName(accountNameValue);
      listReq.setAccountDesignator(accountDesignatorValue);
      listReq.setBroker(brokerValue);
      listReq.setStatus(statusValue);

      accountClient.listClient(listReq, {}, (err, res) => {
        if (err) {
          console.error(err);
          notifyError(err.message);
          return;
        }
        let fullData = res.toObject().clientsList;
        setPageState({
          ...page,
          totalRows: fullData.length,
          searchSrc: type,
          currentPage: cPage,
          displayedRows: rowsPerPage,
          data: fullData,
          sortOrder: { name: field, direction: sortDirection },
        });
      });
    } else {
      setPageState({
        ...page,
        searchSrc: type,
        currentPage: cPage,
        displayedRows: rowsPerPage,
        sortOrder: { name: field, direction: sortDirection },
        filter: filterValue,
      });
    }
  };

  const handleSearch = (page) => {
    search('search', 0, page.displayedRows);
  };
  /*=========================================================================================
    End of paging logic
  ===========================================================================================*/

  /*=========================================================================================
  Handle tab
  ===========================================================================================*/
  const [value, setValue] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  /*=========================================================================================
  End of Handle tab
  ===========================================================================================*/

  /*=========================================================================================
  Table configuration
  ===========================================================================================*/
  const [selected, setSelected] = React.useState([]);
  const [rowID, setRowID] = React.useState('');

  const columns = [
    {
      id: '1',
      name: 'accountDesignator',
      label: 'Account Designator',
      visibleState: useState(true),
      align: 'left',
      order: 5,
    },
    {
      id: '2',
      name: 'accountName',
      label: 'Account Name',
      visibleState: useState(true),
      align: 'left',
      order: 1,
    },
    {
      id: '3',
      name: 'accountNo',
      label: 'Account No',
      visibleState: useState(true),
      align: 'left',
      order: 2,
    },
    {
      id: '4',
      name: 'activatedAt',
      label: 'Activated At',
      visibleState: useState(true),
      align: 'left',
      order: 6,
    },
    {
      id: '5',
      name: 'broker',
      label: 'Broker',
      visibleState: useState(true),
      align: 'left',
      order: 8,
    },
    {
      id: '6',
      name: 'capacity',
      label: 'Capacity',
      visibleState: useState(true),
      align: 'right',
      order: 9,
    },
    {
      id: '7',
      name: 'correspondent',
      label: 'Correspondent',
      visibleState: useState(true),
      align: 'left',
      order: 0,
    },
    {
      id: '8',
      name: 'createdBy',
      label: 'Created By',
      visibleState: useState(true),
      align: 'left',
      order: 20,
    },
    {
      id: '9',
      name: 'dayTrader',
      label: 'Day Trader',
      visibleState: useState(true),
      align: 'left',
      order: 13,
    },
    {
      id: '10',
      name: 'disabledAt',
      label: 'Disabled At',
      visibleState: useState(true),
      align: 'left',
      order: 7,
    },
    {
      id: '11',
      name: 'investmentObjectiveCode',
      label: 'Investment Objective Code',
      visibleState: useState(false),
      align: 'left',
      order: 15,
    },
    {
      id: '12',
      name: 'investmentObjectiveDate',
      label: 'Investment Objective Date',
      visibleState: useState(false),
      align: 'left',
      order: 10,
    },
    {
      id: '14',
      name: 'legalEntity',
      label: 'Legal Entry',
      visibleState: useState(false),
      align: 'left',
      order: 16,
    },
    {
      id: '15',
      name: 'masterAccountNo',
      label: 'Master Account No',
      visibleState: useState(false),
      align: 'left',
      order: 3,
    },
    {
      id: '16',
      name: 'multiplier',
      label: 'Multiplier',
      visibleState: useState(false),
      align: 'right',
      order: 14,
    },
    {
      id: '17',
      name: 'privacyContent',
      label: 'Privacy Content',
      visibleState: useState(false),
      align: 'left',
      order: 12,
    },
    {
      id: '18',
      name: 'status',
      label: 'Status',
      visibleState: useState(false),
      align: 'left',
      order: 4,
    },
    {
      id: '19',
      name: 'taxCountry',
      label: 'Tax Country',
      visibleState: useState(false),
      align: 'left',
      order: 17,
    },
    {
      id: '20',
      name: 'tefra',
      label: 'TEFRA',
      visibleState: useState(false),
      align: 'left',
      order: 19,
    },
    {
      id: '21',
      name: 'w8w9',
      label: 'W8W9',
      visibleState: useState(false),
      align: 'left',
      order: 18,
    },
    {
      id: '22',
      name: 'w8w9ReceivedDate',
      label: 'W8W9 Received Date',
      visibleState: useState(false),
      align: 'left',
      order: 11,
    },
    {
      id: '23',
      name: 'baseCurrency',
      label: 'Base Currency',
      visibleState: useState(false),
      align: 'left',
      order: 21,
    },
  ];

  const sortedColumns = columns.slice(0);
  sortedColumns.sort(function(a, b) {
    return a.order - b.order;
  });

  const setVisibles = {};
  columns.forEach((col) => {
    if (col.name === '') {
      col.options = {
        display: true,
        viewColumns: false,
        empty: true,
      };
      return;
    }
    col.options = {
      display: col.visibleState[0],
    };
    setVisibles[col.name] = col.visibleState[1];
    return;
  });

  const handleClick = (event, accountId, removed) => {
    if (!removed) {
      const selectedIndex = selected.indexOf(accountId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, accountId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
      setRowID(newSelected);
    } else {
      setSelected([]);
    }
  };

  const isSelected = (accountId) => selected.indexOf(accountId) !== -1;

  const customTableRow = (data, dataIndex, rowIndex) => {
    if (!rows[rowIndex]) {
      return;
    }

    const isItemSelected = isSelected(rows[rowIndex].accountId);
    const labelId = `enhanced-table-checkbox-${rowIndex}`;

    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={rows[rowIndex].accountId}
        selected={isItemSelected}
        aria-label={labelId}
      >
        <TableCell padding="checkbox">
          <div className={classes.grdRow} style={{ margin: 0 }}>
            <div className={classes.grdCellNone}>
              <Checkbox
                onClick={(event) =>
                  handleClick(event, rows[rowIndex].accountId)
                }
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </div>
            <div className={classes.grdCellNone}>
              <IconButton
                aria-label="delete"
                disabled={rows[rowIndex].status === 'disabled' ? true : false}
                onClick={() => {
                  removeClientAccountSingle(
                    accountClient,
                    rows,
                    setRows,
                    rows[rowIndex].accountId,
                    rows[rowIndex].accountNo,
                    rows[rowIndex].accountName,
                    confirm
                  );
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
            <div className={classes.grdCellNone}>
              <IconButton
                aria-label="edit"
                onClick={() => handleOpen(rows[rowIndex], false)}
              >
                <EditIcon />
              </IconButton>
            </div>
          </div>
        </TableCell>
        {sortedColumns
          .filter((v) => v.options.display)
          .map((col) => (
            <TableCell align={col.align || 'left'} key={col.name}>
              {rows[rowIndex][col.name]}
            </TableCell>
          ))}
      </TableRow>
    );
  };

  const showHideColumns = (column, action) => {
    setVisibles[column](action === 'remove' ? false : true);
  };

  const fileName =
    'ClientAccounts_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    customRowRender: customTableRow,
    onColumnViewChange: showHideColumns,
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [
      6,
      1,
      2,
      13,
      16,
      0,
      3,
      9,
      4,
      5,
      11,
      20,
      15,
      8,
      14,
      10,
      12,
      17,
      19,
      18,
      7,
      21,
    ],
    count: page.totalRows,
    serverSide: true,
    rowsPerPage: page.displayedRows,
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'filterChange': {
          let filterValues = [];

          for (let i = 0; i < tableState.filterList.length; i++) {
            if (tableState.filterList[i].length > 0) {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            } else {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            }
          }
          search(
            'filterChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            tableState.sortOrder.direction,
            filterValues
          );
          break;
        }
        case 'resetFilters': {
          search('search', 0, page.displayedRows);
          break;
        }
        case 'changePage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'sort': {
          search(
            'sortChange',
            tableState.page,
            tableState.rowsPerPage,
            tableState.columns[tableState.activeColumn].name,
            tableState.sortOrder.direction,
            page.filter
          );
          break;
        }
        case 'changeRowsPerPage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'propsUpdate': {
          return { ...tableState, count: page.totalRows };
        }
        default: {
          break;
        }
      }
    },
  };
  /*=========================================================================================
  End of table configuration
  ===========================================================================================*/
  const [rows, setRows] = useState([]);
  const [downloadableRows, setDownloadableRows] = useState([]);
  /*=========================================================================================
  Search input fields
  ===========================================================================================*/
  const [correspondentValue, setCorrespondentValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [masterAccountNoValue, setMasterAccountNoValue] = React.useState('');
  const [accountNameValue, setAccountNameValue] = React.useState('');
  const [accountDesignatorValue, setAccountDesignatorValue] = React.useState(
    ''
  );
  const [brokerValue, setBrokerValue] = React.useState('');
  const [statusValue, setStatusValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      correspondent: query.get('correspondent'),
      accountNo: query.get('accountNo'),
      masterAccountNo: query.get('masterAccountNo'),
      accountName: query.get('accountName'),
      accountDesignator: query.get('accountDesignator'),
      broker: query.get('broker'),
      status: query.get('status'),
    };

    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.masterAccountNo) {
      setMasterAccountNoValue(data.masterAccountNo);
    }
    if (data.accountName) {
      setAccountNameValue(data.accountName);
    }
    if (data.accountDesignator) {
      setAccountDesignatorValue(data.accountDesignator);
    }
    if (data.broker) {
      setBrokerValue(data.broker);
    }
    if (data.status) {
      setStatusValue(data.status);
    }

    setCurrentPath(path);
  }, [params]);
  /*=========================================================================================
  End of search input fields
  ===========================================================================================*/

  /*=========================================================================================
  Modal general tab input fields
  ===========================================================================================*/
  const [accountId, setAccountId] = React.useState('');
  const [accountNo, setAccountNo] = React.useState('');
  const [addressList, setAddressList] = React.useState([]);
  const [subAccountList, setSubAccountList] = React.useState([]);
  const [accountTypeList, setAccountTypeList] = React.useState([]);
  const [reportSetupList, setReportSetupList] = React.useState([]);
  const [contactInfoList, setContactInfoList] = React.useState([]);
  const [ownerList, setOwnerList] = React.useState([]);
  const [noteList, setNoteList] = React.useState([]);
  const [noticeList, setNoticeList] = React.useState([]);

  const [modalCorrespondentValue, setModalCorrespondentValue] = React.useState(
    ''
  );
  const [modalAccountNoValue, setModalAccountNoValue] = React.useState('');
  const [modalBaseCurrencyValue, setModalBaseCurrencyValue] = React.useState(
    ''
  );
  const [modalAccountNameValue, setModalAccountNameValue] = React.useState('');
  const [
    modalMasterAccountNoValue,
    setModalMasterAccountNoValue,
  ] = React.useState('');
  const [modalStatusValue, setModalStatusValue] = React.useState('Active');
  const [
    modalAccountDesignatorValue,
    setModalAccountDesignatorValue,
  ] = React.useState('');
  const [modalActivatedAtValue, setModalActivatedAtValue] = React.useState('');
  const [modalDisabledAtValue, setModalDisabledAtValue] = React.useState('');
  const [modalBrokerValue, setModalBrokerValue] = React.useState('');
  const [modalCapacityValue, setModalCapacityValue] = React.useState('');
  const [modalPrivacyValue, setModalPrivacyValue] = React.useState(false);
  const [modalDayTraderValue, setModalDayTraderValue] = React.useState(false);
  const [modalMultiplierValue, setModalMultiplierValue] = React.useState('');
  const [modalLegalEntityValue, setModalLegalEntityValue] = React.useState('');
  const [modalInvestmentObjValue, setModalInvestmentObjValue] = React.useState(
    ''
  );
  const [
    modalInvestmentObjDateValue,
    setModalInvestmentObjDateValue,
  ] = React.useState('');
  const [modalTaxCountryValue, setModalTaxCountryValue] = React.useState('');
  const [modalTefraValue, setModalTefraValue] = React.useState('');
  const [modalW8W9Value, setModalW8W9Value] = React.useState('');
  const [
    modalW8W9ReceivedDateValue,
    setModalW8W9ReceivedDateValue,
  ] = React.useState('');
  const [modelClearingBroker, setModelClearingBroker] = React.useState('');

  const [disableCorrespondent, setDisableCorrespondent] = React.useState(false);
  const [disableAccountNo, setDisableAccountNo] = React.useState(false);
  const [disableBaseCurrency, setDisableBaseCurrency] = React.useState(false);
  /*=========================================================================================
  End of modal general tab input fields
  ===========================================================================================*/

  /*=========================================================================================
  Handles modal
  ===========================================================================================*/
  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const handleOpen = (rowData, isAdd) => {
    if (isAdd) {
      setModalCorrespondentValue('');
      setModalAccountNoValue('');
      setModalBaseCurrencyValue('usd');
      setModalAccountNameValue('');
      setModalMasterAccountNoValue('');
      setModalStatusValue('active');
      setModalAccountDesignatorValue('customer');
      setModalActivatedAtValue(new Date().toISOString().substring(0, 16));
      setModalDisabledAtValue('');
      setModalBrokerValue('non_broker_dealer');
      setModalCapacityValue('agency');
      setModalPrivacyValue(false);
      setModalDayTraderValue(false);
      setModalMultiplierValue('1');
      setModalLegalEntityValue('individual');
      setModalInvestmentObjValue('other');
      setModalInvestmentObjDateValue(moment(new Date()).format('yyyy-MM-DD'));
      setModalTaxCountryValue('US');
      setModalTefraValue('exempt');
      setModalW8W9Value('w9');
      setModalW8W9ReceivedDateValue(moment(new Date()).format('yyyy-MM-DD'));

      setDisableCorrespondent(false);
      setDisableAccountNo(false);
      setDisableBaseCurrency(false);
      setModalAddAction(true);
      setModalTitleValue('Add New');

      let newUUID = new GetNewClientIdRequest();

      accountClient.getNewClientId(newUUID, {}, (err, res) => {
        if (err) {
          console.log('error', err);
          notifyError(err.message);
          return;
        }
        setAccountId(res.toObject().accountId);
      });
    } else {
      setAccountId(rowData.accountId);
      setModalCorrespondentValue(rowData.correspondent);
      setModalAccountNoValue(rowData.accountNo);
      setModalBaseCurrencyValue(rowData.baseCurrency?.toLowerCase());
      setModalAccountNameValue(rowData.accountName);
      setModalMasterAccountNoValue(rowData.masterAccountNo);
      setModalStatusValue(rowData.status);
      setModalAccountDesignatorValue(rowData.accountDesignator);
      setModalActivatedAtValue(stringToModalDate(rowData.activatedAt));
      setModalDisabledAtValue(stringToModalDate(rowData.disabledAt));
      setModalBrokerValue(rowData.broker);
      setModalCapacityValue(rowData.capacity);
      setModalPrivacyValue(rowData.privacyContent);
      setModalDayTraderValue(rowData.dayTrader);
      setModalMultiplierValue(rowData.multiplier ? rowData.multiplier : '1');
      setModalLegalEntityValue(rowData.legalEntity);
      setModalInvestmentObjValue(rowData.investmentObjectiveCode);
      setModalInvestmentObjDateValue(
        rowData.investmentObjectiveDate
          ? moment(new Date(rowData.investmentObjectiveDate)).format(
              'yyyy-MM-DD'
            )
          : ''
      );
      setModalTaxCountryValue(rowData.taxCountry);
      setModalTefraValue(rowData.tefra);
      setModalW8W9Value(rowData.w8w9);
      setModalW8W9ReceivedDateValue(
        rowData.w8w9ReceivedDate
          ? moment(new Date(rowData.w8w9ReceivedDate)).format('yyyy-MM-DD')
          : ''
      );
      setModelClearingBroker('');

      setDisableCorrespondent(true);
      setDisableAccountNo(true);
      setDisableBaseCurrency(true);
      setModalAddAction(false);
      setModalTitleValue('Edit');

      setAccountId(rowData.accountId);

      let readReq = new ReadClientRequest();
      readReq.setAccountId(rowData.accountId);

      accountClient.readClient(readReq, {}, (err, res) => {
        if (err) {
          console.log(err);
          notifyError(err.message);
          return;
        }
        setAddressList(res.toObject().addressesList);
        setAccountTypeList(res.toObject().accountTypesList);
        setSubAccountList(res.toObject().subAccountsList);
        setReportSetupList(res.toObject().reportSetupsList);
        setContactInfoList(res.toObject().contactInfosList);
        setOwnerList(res.toObject().ownersList);
        setNoteList(res.toObject().notesList);
      });

      let noticeReq = new ListNoticeRequest();
      noticeReq.setAccountNo(rowData.accountNo);

      notice.listNotice(noticeReq, {}, (err, res) => {
        if (err) {
          console.log(err);
          notifyError(err.message);
          return;
        }
        let newNoticeList = res.toObject().noticeList.map((data) => {
          let d = {
            ...data,
            startDate: formatPbDate(data.startDate),
            endDate: formatPbDate(data.endDate),
            createdAt: formatSeconds(data.createdAt.seconds),
          };
          if (data.updatedAt) {
            d.updatedAt = formatSeconds(data.updatedAt.seconds);
          }
          return d;
        });
        setNoticeList(newNoticeList);
        setAccountNo(rowData.accountNo);
      });
    }
    setOpen(true);
    handleChangeTab('', 0);
  };

  const handleClose = () => {
    setAddressList([]);
    setSubAccountList([]);
    setAccountTypeList([]);
    setReportSetupList([]);
    setContactInfoList([]);
    setOwnerList([]);
    setNoteList([]);
    setNoticeList([]);

    setOpen(false);
  };
  /*=========================================================================================
  End of modal input fields
  ===========================================================================================*/

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <CorrespondentSelect
                  freeSolo={false}
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNoSelect
                  freeSolo={false}
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={
                    correspondentValue ? correspondentValue : ''
                  }
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <MasterAccountNoSelect
                  freeSolo={false}
                  name="masterAccountNo"
                  label="Master Account Number"
                  value={masterAccountNoValue}
                  onChange={(e) => {
                    setMasterAccountNoValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}></div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNameSelect
                  freeSolo={true}
                  name="accountName"
                  label="Account Name"
                  value={accountNameValue}
                  onChange={(e) => {
                    setAccountNameValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink id="account_designator">
                  Account Designator
                </InputLabel>
                <AccountDesignatorSelect
                  required={false}
                  labelId="accountDesignator"
                  fullWidth
                  value={accountDesignatorValue}
                  onChange={(e) => setAccountDesignatorValue(e.target.value)}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink id="broker">
                  Broker
                </InputLabel>
                <Select
                  labelId="broker"
                  displayEmpty
                  fullWidth
                  value={brokerValue}
                  onChange={(e) => setBrokerValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="broker_dealer">Broker Dealer</MenuItem>
                  <MenuItem value="non_broker_dealer">
                    Non Broker Dealer
                  </MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1}>
                <InputLabel shrink id="status">
                  Status
                </InputLabel>
                <Select
                  labelId="status"
                  displayEmpty
                  fullWidth
                  value={statusValue}
                  onChange={(e) => setStatusValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="limited">Limited</MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="disabled">Disabled</MenuItem>
                </Select>
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                removeClientAccount(
                  accountClient,
                  rows,
                  setRows,
                  rowID,
                  handleClick,
                  confirm
                );
              }}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </div>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => handleSearch(page)}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {downloadableRows && (
          <CSVLink
            id="csvDL"
            data={downloadableRows}
            filename={fileName}
            target="_blank"
          />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Client Accounts'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.tabModalLg}>
            <div className={classes.tabModalHeader}>
              <Typography
                id="transition-modal-title"
                variant="h4"
                className={classes.textBold}
                gutterBottom
              >
                {modalTitleValue} Client Account
              </Typography>
            </div>
            <Box id="transition-modal-description">
              <AppBar position="static" className={classes.tabHeader}>
                <Tabs
                  value={value}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="General" {...a11yProps(0)} />
                  <Tab label="Address" {...a11yProps(1)} />
                  <Tab label="Owner" {...a11yProps(2)} />
                  <Tab label="Note" {...a11yProps(3)} />
                  <Tab label="Contact Info" {...a11yProps(4)} />
                  <Tab label="Setup" {...a11yProps(5)} />
                  <Tab label="Notice" {...a11yProps(6)} />
                </Tabs>
              </AppBar>
              <form className={classes.modalForm} noValidate autoComplete="off">
                <TabPanel className={classes.tabBody} value={value} index={0}>
                  <div className={classes.grdRow}>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <TextField
                        required
                        label="Correspondent"
                        disabled={disableCorrespondent}
                        fullWidth={true}
                        value={modalCorrespondentValue}
                        onChange={(e) =>
                          setModalCorrespondentValue(e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <TextField
                        required
                        label="Account No"
                        disabled={disableAccountNo}
                        fullWidth={true}
                        value={modalAccountNoValue}
                        onChange={(e) => setModalAccountNoValue(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <TextField
                        required
                        label="Account Name"
                        fullWidth={true}
                        value={modalAccountNameValue}
                        onChange={(e) =>
                          setModalAccountNameValue(e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div className={classes.grdCell1}>
                      <TextField
                        label="Master Account No."
                        fullWidth={true}
                        value={modalMasterAccountNoValue}
                        onChange={(e) =>
                          setModalMasterAccountNoValue(e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                  <div className={classes.grdRow}>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel shrink id="status">
                        Status
                      </InputLabel>
                      <Select
                        labelId="status"
                        displayEmpty
                        fullWidth
                        value={modalStatusValue}
                        onChange={(e) => setModalStatusValue(e.target.value)}
                      >
                        <MenuItem value="pending">Pending</MenuItem>
                        <MenuItem value="limited">Limited</MenuItem>
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="disabled">Disabled</MenuItem>
                      </Select>
                    </div>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel shrink id="account_designator">
                        Account Designator
                      </InputLabel>
                      <AccountDesignatorSelect
                        required={false}
                        labelId="accountDesignator"
                        fullWidth
                        value={modalAccountDesignatorValue}
                        onChange={(e) =>
                          setModalAccountDesignatorValue(e.target.value)
                        }
                      />
                    </div>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <TextField
                        fullWidth
                        label="Activated At"
                        type="datetime-local"
                        className={classes.textField}
                        value={modalActivatedAtValue}
                        onChange={(e) =>
                          setModalActivatedAtValue(e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div className={classes.grdCell1}>
                      <TextField
                        fullWidth
                        label="Disabled At"
                        type="datetime-local"
                        className={classes.textField}
                        value={modalDisabledAtValue}
                        onChange={(e) =>
                          setModalDisabledAtValue(e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                  <div className={classes.grdRow}>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel shrink id="broker">
                        Broker
                      </InputLabel>
                      <Select
                        labelId="broker"
                        displayEmpty
                        fullWidth
                        value={modalBrokerValue}
                        onChange={(e) => setModalBrokerValue(e.target.value)}
                      >
                        <MenuItem value="broker_dealer">Broker Dealer</MenuItem>
                        <MenuItem value="non_broker_dealer">
                          Non Broker Dealer
                        </MenuItem>
                      </Select>
                    </div>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel shrink id="capacity">
                        Capacity
                      </InputLabel>
                      <Select
                        labelId="capacity"
                        displayEmpty
                        fullWidth
                        value={modalCapacityValue}
                        onChange={(e) => setModalCapacityValue(e.target.value)}
                      >
                        <MenuItem value="">
                          <em></em>
                        </MenuItem>
                        <MenuItem value="agency">Agency</MenuItem>
                        <MenuItem value="market_maker">Market Maker</MenuItem>
                        <MenuItem value="principal">Principal</MenuItem>
                        <MenuItem value="riskless_principal">
                          Riskless Principal
                        </MenuItem>
                      </Select>
                    </div>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                        label="Privacy Content"
                        checked={modalPrivacyValue}
                        onChange={(e) => setModalPrivacyValue(e.target.checked)}
                      />
                    </div>
                    <div className={classes.grdCell1}>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                        label="Day Trader"
                        checked={modalDayTraderValue}
                        onChange={(e) =>
                          setModalDayTraderValue(e.target.checked)
                        }
                      />
                    </div>
                  </div>
                  <div className={classes.grdRow}>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <TextField
                        label="Multiplier"
                        type="number"
                        fullWidth={true}
                        value={modalMultiplierValue}
                        onChange={(e) =>
                          setModalMultiplierValue(e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel shrink>Legal Entity *</InputLabel>
                      <Select
                        required
                        displayEmpty
                        fullWidth
                        value={modalLegalEntityValue}
                        onChange={(e) =>
                          setModalLegalEntityValue(e.target.value)
                        }
                      >
                        <MenuItem value="business_trust">
                          Business Trust
                        </MenuItem>
                        <MenuItem value="corporation">Corporation</MenuItem>
                        <MenuItem value="custodial">
                          Custodial (UGMA/UTMA)
                        </MenuItem>
                        <MenuItem value="general_partnership">
                          General Partnership
                        </MenuItem>
                        <MenuItem value="investment_club">
                          Investment Club
                        </MenuItem>
                        <MenuItem value="individual">Individual</MenuItem>
                        <MenuItem value="401k">401(k)</MenuItem>
                        <MenuItem value="qualified_retirement_plan">
                          Qualified Retirement Plan
                        </MenuItem>
                        <MenuItem value="roth_ira">Roth IRA</MenuItem>
                        <MenuItem value="rollover_ira">Rollover IRA</MenuItem>
                        <MenuItem value="sep_ira">SEP IRA</MenuItem>
                        <MenuItem value="traditional_ira">
                          Traditional IRA
                        </MenuItem>
                        <MenuItem value="joint_community_property">
                          Joint - Community Property
                        </MenuItem>
                        <MenuItem value="joint_wrights_of_survivorship">
                          Joint - W/Rights of Survivorship
                        </MenuItem>
                        <MenuItem value="joint_tenants_by_entirety">
                          Joint - Tenants by Entirety
                        </MenuItem>
                        <MenuItem value="joint_tenants_in_common">
                          Joint - Tenants in common
                        </MenuItem>
                        <MenuItem value="limited_liability_corporation">
                          Limited Liability Corp.
                        </MenuItem>
                        <MenuItem value="limited_partnership">
                          Limited Partnership
                        </MenuItem>
                        <MenuItem value="sole_proprietorship">
                          Sole Proprietorship
                        </MenuItem>
                        <MenuItem value="trust">Trust</MenuItem>
                        <MenuItem value="unIncorporated_organization">
                          UnIncorporated Organization
                        </MenuItem>
                      </Select>
                    </div>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel shrink>Investment Objective Code</InputLabel>
                      <Select
                        displayEmpty
                        fullWidth
                        value={modalInvestmentObjValue}
                        onChange={(e) =>
                          setModalInvestmentObjValue(e.target.value)
                        }
                      >
                        <MenuItem value="income">Income</MenuItem>
                        <MenuItem value="speculation">Speculation</MenuItem>
                        <MenuItem value="trading_profits">
                          Trading Profits
                        </MenuItem>
                        <MenuItem value="preservation_of_capital">
                          Preservation of Capital
                        </MenuItem>
                        <MenuItem value="capital_appreciation">
                          Capital Appreciation
                        </MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                    </div>
                    <div className={classes.grdCell1}>
                      <TextField
                        fullWidth
                        label="Investment Objective Date"
                        type="date"
                        className={classes.textField}
                        value={modalInvestmentObjDateValue}
                        onChange={(e) =>
                          setModalInvestmentObjDateValue(e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                  <div className={classes.grdRow}>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel id="country" shrink>
                        Tax Country
                      </InputLabel>
                      <CountriesSelect
                        labelid="country"
                        value={modalTaxCountryValue}
                        onchange={(e) =>
                          setModalTaxCountryValue(e.target.value)
                        }
                        setNewValue={(event, newValue) => {
                          if (newValue) {
                            setModalTaxCountryValue(newValue.code);
                          } else {
                            setModalTaxCountryValue('');
                            return;
                          }
                        }}
                      />
                    </div>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel id="tefra" shrink>
                        Tefra
                      </InputLabel>
                      <Select
                        displayEmpty
                        labelId="tefra"
                        fullWidth
                        value={modalTefraValue}
                        onChange={(e) => setModalTefraValue(e.target.value)}
                      >
                        <MenuItem value="exempt">Exempt</MenuItem>
                        <MenuItem value="non_exempt">Non Exempt</MenuItem>
                      </Select>
                    </div>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel id="w8w9" shrink>
                        W8W9
                      </InputLabel>
                      <Select
                        displayEmpty
                        labelId="w8w9"
                        fullWidth
                        value={modalW8W9Value}
                        onChange={(e) => setModalW8W9Value(e.target.value)}
                      >
                        <MenuItem value="w9">W9</MenuItem>
                        <MenuItem value="w8">W8</MenuItem>
                      </Select>
                    </div>
                    <div className={classes.grdCell1}>
                      <TextField
                        required
                        fullWidth
                        label="W8W9 Received Date"
                        type="date"
                        className={classes.textField}
                        value={modalW8W9ReceivedDateValue}
                        onChange={(e) =>
                          setModalW8W9ReceivedDateValue(e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                  <div className={classes.grdRow}>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel id="baseCurrency" shrink>
                        Base Currency
                      </InputLabel>
                      <Select
                        displayEmpty
                        labelId="baseCurrency"
                        disabled={disableBaseCurrency}
                        fullWidth
                        value={modalBaseCurrencyValue}
                        onChange={(e) =>
                          setModalBaseCurrencyValue(e.target.value)
                        }
                      >
                        <MenuItem value="usd">USD</MenuItem>
                        <MenuItem value="cad">CAD</MenuItem>
                        <MenuItem value="chf">CHF</MenuItem>
                        <MenuItem value="eur">EUR</MenuItem>
                        <MenuItem value="gbp">GBP</MenuItem>
                        <MenuItem value="jpy">JPY</MenuItem>
                        <MenuItem value="try">TRY</MenuItem>
                        <MenuItem value="aud">AUD</MenuItem>
                        <MenuItem value="sgd">SGD</MenuItem>
                      </Select>
                    </div>
                    {modalAddAction ? (
                      <div
                        className={classes.grdCell1}
                        style={{ marginRight: 30 }}
                      >
                        <InputLabel id="clearingBroker" shrink>
                          Clearing Broker
                        </InputLabel>
                        <Select
                          displayEmpty
                          labelId="clearingBroker"
                          fullWidth
                          value={modelClearingBroker}
                          onChange={(e) =>
                            setModelClearingBroker(e.target.value)
                          }
                        >
                          <MenuItem value="VELOX">VELOX</MenuItem>
                          <MenuItem value="VISION">VISION</MenuItem>
                          <MenuItem value="PAPERV3">PAPERV3</MenuItem>
                        </Select>
                      </div>
                    ) : (
                      <div className={classes.grdCell1}></div>
                    )}
                    <div className={classes.grdCell1}></div>
                    <div className={classes.grdCell1}></div>
                  </div>
                </TabPanel>
                <TabPanel className={classes.tabBody} value={value} index={1}>
                  <AddressTable
                    accountid={accountId}
                    addresslist={addressList}
                    style={{ paddingBottom: 0 }}
                  />
                </TabPanel>
                <TabPanel className={classes.tabBody} value={value} index={2}>
                  <OwnerTable
                    accountid={accountId}
                    ownerlist={ownerList}
                    generalTaxCountry={modalTaxCountryValue}
                    style={{ paddingBottom: 0 }}
                  />
                </TabPanel>
                <TabPanel className={classes.tabBody} value={value} index={3}>
                  <NoteTable
                    accountid={accountId}
                    notelist={noteList}
                    style={{ paddingBottom: 0 }}
                  />
                </TabPanel>
                <TabPanel className={classes.tabBody} value={value} index={4}>
                  <ContactInfoTable
                    accountid={accountId}
                    contactinfolist={contactInfoList}
                    style={{ paddingBottom: 0 }}
                  />
                </TabPanel>
                <TabPanel className={classes.tabBody} value={value} index={5}>
                  <Box>
                    <ClientTypeTable
                      accountid={accountId}
                      accounttypelist={accountTypeList}
                    />
                  </Box>
                  <Box mt={5}>
                    <SubAccountTable
                      accountid={accountId}
                      subaccountlist={subAccountList}
                    />
                  </Box>
                  <Box mt={5}>
                    <SetupTable
                      accountid={accountId}
                      reportsetuplist={reportSetupList}
                    />
                  </Box>
                </TabPanel>
                <TabPanel className={classes.tabBody} value={value} index={6}>
                  <NoticeTable
                    accountNo={accountNo}
                    noticeList={noticeList}
                    style={{ paddingBottom: 0 }}
                  />
                </TabPanel>
                <div className={classes.tabModalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveClientAccount(
                          accountClient,
                          rows,
                          modalAddAction,
                          handleClose,
                          accountId,
                          modalCorrespondentValue,
                          modalAccountNameValue,
                          modalAccountNoValue,
                          modalMasterAccountNoValue,
                          modalStatusValue,
                          modalAccountDesignatorValue,
                          modalActivatedAtValue,
                          modalDisabledAtValue,
                          modalBrokerValue,
                          modalCapacityValue,
                          modalPrivacyValue,
                          modalDayTraderValue,
                          modalMultiplierValue,
                          modalLegalEntityValue,
                          modalInvestmentObjValue,
                          modalInvestmentObjDateValue,
                          modalTaxCountryValue,
                          modalTefraValue,
                          modalW8W9Value,
                          modalW8W9ReceivedDateValue,
                          modalBaseCurrencyValue,
                          modelClearingBroker
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export { ClientAccountTable };
